import React, { useContext } from 'react';

import TopBar from '../components/TopBar';
import HomeContent from '../components/HomeContent';
import Header from '../components/Header';
import Base from '../layout/base';
import { CheckCircleIcon, PencilIcon } from '@heroicons/react/outline';
import TextField, { FieldDummy } from '../components/Form/TextField';
import DocField from '../components/Form/DocField';
import { getIcon } from '../components/StatusIcons';
import BaseTable from '../components/Table/BaseTable';
import H2 from '../components/Content/H2';
import H3 from '../components/Content/H3';
import Status from '../components/Content/Status';
import AdressFormular, { AdressFormularDummy } from '../components/Form/AdressFormular';
import { GlobalContext } from '../context/GlobalState';


const projekte = [
    {
        objektTyp: "Wohnung",
        strasse: "Dorfstrasse 42",
        standort: "Luzern",
        plz: "6005",
        status: 0,
    },
]



const headers = [
    {
        label: "Objekttyp",
        className: "py-1.5 pl-4 pr-3 text-left text-md font-normal text-gray-900 sm:pl-3",
    }, {
        label: "Strasse",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "PLZ",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Ort",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Status",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
]

function Projekt() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);


    const projekteRows = projekte.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-3 w-[20%]",
                    children: (
                        <span>
                            {p.objektTyp}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[35%]",
                    children: (
                        <span>
                            {p.strasse}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.plz}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[25%]",
                    children: (
                        <span>
                            {p.standort}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[25%]",
                    children: (<Status status={p.status} />)
                }
            ]
        }
    });

    return (
        <Base>
            <div className="App">
                <TopBar />
                <div className="py-16 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
                    <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>
                    <Header title="Antragsstellende Person 1" />
                    <div className="bg-white overflow-hidden text-left">
                        <div className="relative">

                            <AdressFormularDummy
                                handleInputChange={() => { }}
                                formState={baseInfo}
                            />
                        </div>
                    </div>



                    <div className="pb-8 bg-white overflow-hidden text-left">
                        <div className="max-w-max lg:max-w-7xl mx-auto">
                            <H3>
                                Dokumente
                            </H3>
                        </div>
                        <div className="relative">

                            <div className="relative md:bg-white pb-8">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                        <DocField
                                            id="name"
                                            label="Gültige Ausweiskopie"
                                            type="text"
                                            placeholder="Mustermann"
                                            autoFocus={true}
                                        />
                                        <DocField
                                            id="adresse"
                                            label="Lohnausweise"
                                            type="text"
                                            placeholder="Dorfstrasse 42"
                                        />
                                        <DocField
                                            id="telefon"
                                            label="Kopie der letzten Steuererklärung"
                                            type="tel"
                                            placeholder="XXX XXX XX XX"
                                        />
                                    </div>
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                        <DocField
                                            id="vorname"
                                            label="Betreibungsregisterauszug"
                                            type="text"
                                            placeholder="Hans"
                                        />
                                        <DocField
                                            id="plz"
                                            label="Belege zu verfügbarem Eigenkapital"
                                            type="text"
                                            placeholder="XXXX"
                                        />
                                        <DocField
                                            id="ort"
                                            label="Nachweis Guthaben 3. Säule"
                                            type="text"
                                            placeholder="Luzern"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-24 bg-white overflow-hidden text-left">
                            <div className="max-w-max lg:max-w-7xl mx-auto">
                                <div className="relative z-10 mb-8">
                                    <H2>
                                        Angaben zur Immobilie
                                    </H2>
                                </div>
                            </div>
                            <div className="relative">

                                <div className="relative md:bg-white pb-8">
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <FieldDummy
                                                id="adresse"
                                                label="Strasse"
                                                type="text"
                                                placeholder="Dorfstrasse"
                                            />
                                            <FieldDummy
                                                id="plz"
                                                label="PLZ"
                                                type="text"
                                                value="6005"
                                            />
                                            <FieldDummy
                                                id="art"
                                                label="Objektart"
                                                type="text"
                                                value={"Wohnung"}
                                            />
                                        </div>
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                            <FieldDummy
                                                id="nummer"
                                                label="Nummer"
                                                type="text"
                                                placeholder="42"
                                            />
                                            <FieldDummy
                                                id="ort"
                                                label="Ort"
                                                type="text"
                                                value="Luzern"
                                            />
                                            <FieldDummy
                                                id="name"
                                                label="Kaufpreis"
                                                type="text"
                                                value={"CHF 1'050'000"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="pb-8 bg-white overflow-hidden text-left">
                            <div className="max-w-max lg:max-w-7xl mx-auto">
                                <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>

                                <H3>
                                    Dokumente
                                </H3>
                            </div>
                            <div className="relative">

                                <div className="relative md:bg-white">
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <DocField
                                                id="name"
                                                label="Situations- / Katasterplan"
                                                type="text"
                                                placeholder="Mustermann"
                                                autoFocus={true}
                                            />
                                            <DocField
                                                id="adresse"
                                                label="Gebäudeversicherungsnachweis"
                                                type="text"
                                                placeholder="Dorfstrasse 42"
                                            />
                                            <DocField
                                                id="telefon"
                                                label="Verkaufsdokumentation"
                                                type="tel"
                                                placeholder="XXX XXX XX XX"
                                            />
                                            <DocField
                                                id="vorname"
                                                label="Letztjährige Betriebs- und Nebenkostenabrechnung"
                                                type="text"
                                                placeholder="Hans"
                                            />
                                        </div>
                                        <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                            <DocField
                                                id="vorname"
                                                label="Plan / Grundriss des Gebäudes"
                                                type="text"
                                                placeholder="Hans"
                                            />
                                            <DocField
                                                id="plz"
                                                label="Fotos der Liegenschaft innen und aussen"
                                                type="text"
                                                placeholder="XXXX"
                                            />
                                            <DocField
                                                id="ort"
                                                label="Stockwerkeigentumsreglement"
                                                type="text"
                                                placeholder="Luzern"
                                            />
                                            <DocField
                                                id="ort"
                                                label="Plan der Stockwerkeinheit mit Nettofläche in m&sup2;"
                                                type="text"
                                                placeholder="Luzern"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </Base >
    );
}

export default Projekt;
